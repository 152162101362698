import React from "react";

const UIUX = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7">
          <section className="pt-100 pb-50">
            <div className="container">
              <div className="content">
                <h2>Research</h2>
                <p>
                  By researching users within the guidelines of their
                  environment, and collecting important information about the
                  user to create a well-informed view of the visual experience.
                  Our in-depth product research grows insights and brings light
                  additional strategies. This ensures ease of use of the design,
                  and reduces the need for expensive re-work before an
                  investment is made in development. Ultimately, our methods
                  enable us to innovate empathetically and remain focused on the
                  user.
                </p>
              </div>
            </div>
          </section>
          <section className="pt-100 pb-50">
            <div className="container">
              <div className="content">
                <h2>Strategy</h2>
                <p>
                  Equipped with our research findings, we are able to plan with
                  our clients the most effective path to get to market.
                  Together, we look beyond the boundaries of thinking in terms
                  of features and functions and launch into considering the real
                  holistic needs of the people who will use the product. We
                  employ a combination of strategy tools that can vary according
                  to specific project and client needs. The result is that we
                  establish meaningful user experience strategies for our
                  clients, success metrics, deliverables, and long-term
                  governance.
                </p>
              </div>
            </div>
          </section>
          <section className="pt-100 pb-50">
            <div className="container">
              <div className="content">
                <h2>Design</h2>
                <p>
                  We create digital experiences for any platform that reflect
                  your brand, vision, and end-user needs. Taking the Research
                  and Strategy findings, we start the iterative design process.
                  Through a variety of collaborative techniques we spark not
                  only the creation of individual screens but also generate a
                  consistent and reusable design language as we progress through
                  interaction and visual design. Throughout all of this, we keep
                  the user at the center of every decision. This allows the
                  synthesis of ideas to encapsulate the entire user experience,
                  beyond a single isolated product interaction, and keep it as a
                  set of actions, perceptions, feelings, and emotions that
                  begins the moment a user wakes up to the moment they go to
                  sleep at night.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-5">
          <div style={{ width: "100%" }}>
            <img src="assets/img/what-we-do/uiux.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIUX;
