import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
function TeamModal({ show, setShow, imageUrl, name, role, description }) {

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Team Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column" }}>
            <img src={imageUrl} style={{ width: '250px', height: '250px' }} />
            <br />
            <h4>{name}</h4>
            <p class="lead">{role}</p>
            <p>{description}</p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer> */}
        {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
}
export default TeamModal