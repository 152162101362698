import React from 'react'

const AboutUsHeader = () => {
    return (
        <section class="page-title-area-about">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>

            <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"/></div>
            <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"/></div>
            <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"/></div>
        </section>
    )
}

export default AboutUsHeader
