import React from 'react';
import DefaultBanner from '../component/DefaultBanner';
import WebDesign from '../component/what-we-do/web-design';
import MobileAppDevelopment from '../component/what-we-do/mobile-app-development';
import UIUX from '../component/what-we-do/ui-ux';
import BrandingCorporateIdentity from '../component/what-we-do/branding-corporate-identity';
import GraphicDesign from '../component/what-we-do/graphic-design';
import DigitalMarketing from '../component/what-we-do/digital-marketing';
import Seo from '../component/what-we-do/seo';
import Advertising from '../component/what-we-do/advertising';

const SolutionPage = ({ match }) => {
    const solutionParam = match.params.solution;
    console.log(`solution param: ${solutionParam}`)
    let page = ``;
    switch(solutionParam){
        case 'web-design-interactive': 
            return <div>
                <DefaultBanner param={solutionParam} />
                <WebDesign />
                </div>
        case 'mobile-app-development':
            return <div>
                <DefaultBanner param={solutionParam} />
                <MobileAppDevelopment />
            </div>

        case 'ui-ux':
            return <div>
                <DefaultBanner param={solutionParam} />
                <UIUX />
            </div>
        case 'branding-corporate-identity':
            return <div>
                <DefaultBanner param={solutionParam} />
                <BrandingCorporateIdentity />
            </div>
        case 'graphic-design':
            return <div>
                <DefaultBanner param={solutionParam} />
                <GraphicDesign />
            </div>
        case 'digital-marketing':
            return <div>
                <DefaultBanner param={solutionParam} />
                <DigitalMarketing />
            </div>
        case 'seo':
            return <div>
                <DefaultBanner param={solutionParam} />
                <Seo />
            </div>
        case 'advertising':
            return <div>
                <DefaultBanner param={solutionParam} />
                <Advertising />
            </div>
        default: 
            return (
            <div>
                {/* <DefaultBanner param={solutionParam} /> */}
                
            </div>
        )
    }
}

export default SolutionPage
