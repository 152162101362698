import React, { useState } from "react";
import { SOLUTION_MAP, SOLUTIONS} from '../data/solution.data';

const Quote = ({ match }) => {
  const [data, setData] = useState({});
  const slug = match.params.requestQuote;

  const solutions = SOLUTIONS;
  const solutionMap = SOLUTION_MAP; 
  const quoteObj = slug ? solutionMap[slug] : '';

    
  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //   sendQuote(quote);
  };

  return (
    <div>
      <section className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Request Quote</h2>
            <p>{quoteObj.title}</p>
          </div>
        </div>

        <div className="shape-img1">
          <img src="assets/img/shape/shape1.svg" alt="image" />
        </div>
        <div className="shape-img2">
          <img src="assets/img/shape/shape2.png" alt="image" />
        </div>
        <div className="shape-img3">
          <img src="assets/img/shape/shape3.png" alt="image" />
        </div>
      </section>

      <section className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="image" data-tilt>
              <img src="assets/img/history/img2.jpg" alt="image" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <h2>Request a quote</h2>
            <p>Fill the form and we will respond as soon as possible.</p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col form-field" style={fieldStyle}>
                  <label htmlFor="name">Your Name</label> <br />
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col form-field" style={fieldStyle}>
                  <label htmlFor="email">Your email</label> <br />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-field" style={fieldStyle}>
                <label htmlFor="subject">Your subject</label> <br />
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-field" style={fieldStyle}>
                <label htmlFor="solution">Service</label> <br />
                <select
                  className="form-control"
                  value={quoteObj.title}
                  onChange={handleChange}
                >
                  <option>Select Service</option>
                  {solutions.map((solution) => (
                    <option key={solution.id} value={solution.title}>{solution.title}</option>
                  ))}
                </select>
              </div>
              <div className="form-field" style={fieldStyle}>
                <label htmlFor="subject">Your message</label> <br />
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  className="form-control"
                  onChange={handleChange}
                  required
                ></textarea> 
              </div>
              <div style={fieldStyle}></div>
              <button className="default-btn" type="submit">
                Submit
              </button>
              <div style={fieldStyle}></div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

const fieldStyle = {
  margin: "10px 0px",
};

export default Quote;
