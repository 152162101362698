import React from "react";
import { SOLUTIONS, SOLUTION_MAP } from "../data/solution.data";
import { Link } from "react-router-dom";

const DefaultBanner = ({ param }) => {
  const solution = SOLUTION_MAP[param];
  console.log("solution", `assets/img/what-we-do/${solution.image}`);
  return (
    <section className="page-title-area">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="page-title-content">
              <h2>{solution.title}</h2>
              <p style={{ maxWidth: "540px" }}>{solution.description}</p>
              <Link
                to={`/request-quote/${solution.slug}`}
                className="default-btn"
              >
                Request Quote Today
              </Link>
            </div>
          </div>
          <div className="col-md-5">
            <div
              style={{ width: "100%", }}
            >
              <img src={`assets/img/what-we-do/${solution.image}`} alt="" style={{width: '100%', height: '100%'}} />
            </div>
          </div>
        </div>
      </div>

      <div className="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
      </div>
      <div className="shape-img2">
        <img src="assets/img/shape/shape2.png" alt="image" />
      </div>
      <div className="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
      </div>
    </section>
  );
};

export default DefaultBanner;
