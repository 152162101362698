import React, {useState} from 'react';

const Contactform = () => {
    const [contact, setContact] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;

        setContact({...contact, [name]: value})
    }

    const handleSubmit = e => {
        e.preventDefault();

        console.log(contact);
        //contactUs
    }

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title"><img src="assets/img/star-icon.png" alt="image"/> Get in Touch</span>
                    <h2>Ready to Get Started?<span className="overlay-1"></span></h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image" data-tilt>
                            <img src="assets/img/contact.png" alt="image"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" onChange={handleChange} className="form-control" id="name" required data-error="Please enter your name" placeholder="Your name"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" onChange={handleChange} className="form-control" id="email" required data-error="Please enter your email" placeholder="Your email address"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" onChange={handleChange} className="form-control" id="phone_number" required data-error="Please enter your phone number" placeholder="Your phone number"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" id="message" onChange={handleChange} className="form-control" cols="30" rows="6" required data-error="Please enter your message" placeholder="Write your message..."></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn"><i className="flaticon-tick"></i>Send Message<span></span></button>
                                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contactform;
