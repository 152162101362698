import React from "react";

import DefaultLayout from "../component/DefaultLayout";
import Banner from "../component/Banner";
import Solution from "../component/Solution";
import WeLikeToStartProject from "../component/WeLikeToStartProject";
import Partner from "../component/Partner";
import WhyChooseUs from "../component/WhyChooseUs";
import RecentProject from "../component/RecentProject";
import Testimonial from "../component/Testimonial";

const Home = () => {
  return (
    <div>
      <Banner />
      <Solution />
      <WhyChooseUs />
      {/* <Partner /> */}
      <RecentProject />
      {/* <Testimonial /> */}
      <WeLikeToStartProject />
    </div>
  );
};

export default Home;
