export const PROJECT_MAP = {
    "yulo-tranzact": {
        title: 'Yulo Tranzact',
        imageUrl: 'assets/img/projects/dawn-health-care.jpg',
        url: 'https://dawnhealthcareltd.co.uk',
        solution: 'Mobile App Design'
    },
    "qcds": {
        title: 'Qualified Care Domicillary Service',
        imageUrl: 'assets/img/projects/qcds.jpg',
        url: 'http://qcds.org.uk',
        solution: 'Web Design & Development'
    },
    "kayhealthcare": {
        title: 'Kayhealth Care Ltd',
        imageUrl: 'assets/img/projects/kayhealth.PNG',
        url: 'https://kayhealthcare.co.uk',
        solution: 'Web Design & Development'
    },
    "hersandhis": {
        title: 'Hers & His By Duni',
        imageUrl: 'assets/img/projects/hersandhis.png',
        url: 'https://hersandhisbyduni.co.uk',
        solution: 'E-commerce'
    },
    "lsl": {
        title: 'LSL(Limansky Limited)',
        imageUrl: 'assets/img/projects/lsl.png',
        url: 'https://lsl.co.uk',
        solution: "Web Design & Development"
    },
    "caccou": {
        title: 'Caccou Family Services',
        imageUrl: 'assets/img/projects/caccou.png',
        url: 'https://caccoufamilyservices.co.uk',
        solution: "Web Design & Development"
    },
    "manilla": {
        title: 'Manilla Healthcare Services',
        imageUrl: 'assets/img/projects/manilla.png',
        url: 'https://manillahealthcare.co.uk',
        solution: "Web Design & Development"
    },
    "age_healthcare": {
        title: 'Age Healthcare Services',
        imageUrl: 'assets/img/projects/age_healthcare.png',
        url: 'https://agehealthcareservices.co.uk/',
        solution: "Web Design & Development"
    },
    "lkj_ogunewe": {
        title: 'LKJ Ogunewe',
        imageUrl: 'assets/img/projects/lkj_ogunewe.png',
        url: 'https://lkjogunewe.org/',
        solution: "Web Design & Development"
    },
    "precor": {
        title: 'Precor Security Service',
        imageUrl: 'assets/img/projects/lkj_ogunewe.png',
        url: 'https://lkjogunewe.org/',
        solution: "Web Design & Development"
    },
    "evidential_medical": {
        title: 'Evidential Medical',
        imageUrl: 'assets/img/projects/evidential-medical.png',
        url: 'https://evidential-medical.co.uk/',
        solution: "Web Design & Development"
    },
    "crystal": {
        title: 'Crystal Care Bespoke',
        imageUrl: 'assets/img/projects/crystal.png',
        url: 'https://crystalcarebespoke.co.uk/',
        solution: "Web Design & Development"
    },
}

export const PROJECTS = [
    {
        id: 2,
        title: 'Qualified Care Domicillary Service',
        imageUrl: 'assets/img/projects/qcds.jpg',
        url: 'http://qcds.org.uk',
        solution: 'Web Design & Development'
    },
    {
        id: 3,
        title: 'Burkman App - Aspirant Digitals',
        imageUrl: 'assets/img/projects/burkman.jpg',
        url: '#',
        solution: 'Mobile App'
    },
    {
        id: 5,
        title: 'Kayhealth Care Ltd',
        imageUrl: 'assets/img/projects/kayhealth.PNG',
        url: 'https://kayhealthcare.co.uk',
        solution: 'Web Design & Development'
    },
    {
        id: 6,
        title: 'Hers & His By Duni',
        imageUrl: 'assets/img/projects/hersandhis.png',
        url: 'https://hersandhisbyduni.co.uk',
        solution: 'E-commerce'
    },
    {
        id: 7,
        title: 'LSL(Limansky Limited)',
        imageUrl: 'assets/img/projects/lsl.png',
        url: 'https://lsl.co.uk',
        solution: "Web Design & Development"
    },
    {
        id: 8,
        title: 'Caccou Family Services',
        imageUrl: 'assets/img/projects/caccou.png',
        url: 'https://caccoufamilyservices.co.uk',
        solution: "Web Design & Development"
    },
    {
        id: 9,
        title: 'Manilla Healthcare Services',
        imageUrl: 'assets/img/projects/manilla.png',
        url: 'https://manillahealthcare.co.uk',
        solution: "Web Design & Development"
    },
    {
        id: 10,
        title: 'Age Healthcare Services',
        imageUrl: 'assets/img/projects/age_healthcare.png',
        url: 'https://agehealthcareservices.co.uk/',
        solution: "Web Design & Development"
    },
    {
        id: 11,
        title: 'LKJ Ogunewe',
        imageUrl: 'assets/img/projects/lkj_ogunewe.png',
        url: 'https://lkjogunewe.org/',
        solution: "Web Design & Development"
    },
    {
        id: 12,
        title: 'Precor Security Service',
        imageUrl: 'assets/img/projects/precor.png',
        url: 'https://precorsecurity.co.uk/',
        solution: "Web Design & Development"
    },
    {
        id: 13,
        title: 'Evidential Medical',
        imageUrl: 'assets/img/projects/evidential-medical.png',
        url: 'https://evidential-medical.co.uk/',
        solution: "Web Design & Development"
    },
    {
        id: 14,
        title: 'Mannerly Care Management',
        imageUrl: 'assets/img/projects/mannerly-care.png',
        url: 'https://mannerlycaremanagement.co.uk/',
        solution: "Web Design & Development"
    },
    {
        id: 15,
        title: 'Crystal Care Bespoke',
        imageUrl: 'assets/img/projects/crystal.png',
        url: 'https://crystalcarebespoke.co.uk/',
        solution: "Web Design & Development"
    },
];