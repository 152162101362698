import React from 'react';
import { useHistory } from 'react-router-dom';

const Page404 = () => {
    const history = useHistory();
    return (
        <section class="error-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="error-content">
                            <img src="assets/img/error.png" alt="image"/>
                            <h3>Error 404 : Page Not Found</h3>
                            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                            <div class="btn-box">
                                <button class="default-btn" onClick={() => history.goBack() }><i class="flaticon-history"></i>Go Back<span></span></button>
                                <button class="default-btn" onClick={() => history.push('/') }><i class="flaticon-earth-day"></i>Homepage<span></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Page404
