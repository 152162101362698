import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Particles from "react-tsparticles";
const Banner = () => {
  return (
    <div className="it-services-banner" >
      <div class="it-service-banner-overlay"></div>
      <div className="container" style={{ zIndex: 100 }}>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <h1
                className="wow fadeInLeft"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
              >
                Exquisite Digital Solution
              </h1>
              <p
                className="wow fadeInLeft"
                data-wow-delay="100ms"
                data-wow-duration="1000ms"
              >
                As a Buckinghamshire based full service digital agency, we give your
                company the momentum.
              </p>
              <div className="btn-box">
                <Link
                  activeClass="active"
                  to="whyChooseUs"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="default-btn wow fadeInRight text-white"
                >
                  <i className="flaticon-right"></i>Get Started<span></span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div
              className="main-banner-image wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1000ms"
              data-speed="0.06"
              data-revert="true"
            >
              {/* <img src="assets/img/banner-img1.png" alt="image" /> */}
            </div>
          </div>
        </div>
      </div>
      <div style={OverlayStyle}></div>

    </div>
  );
};

const OverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: -200,
  backgroundColor: "rgba(0, 0, 0, 0.6),"
};

export default Banner;
