import React from "react";
import InfoArea from "../component/InfoArea";
import Map from "../component/Map";
import Contactform from "../component/Contactform";

const Contact = () => {
  return (
    <div>
      <section class="page-title-area-contact">
        <div class="container">
          <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>

        <div class="shape-img1">
          <img src="assets/img/shape/shape1.svg" alt="image" />
        </div>
        <div class="shape-img2">
          <img src="assets/img/shape/shape2.png" alt="image" />
        </div>
        <div class="shape-img3">
          <img src="assets/img/shape/shape3.png" alt="image" />
        </div>
      </section>
      <InfoArea />
      <Contactform />
      <Map />
    </div>
  );
};

export default Contact;
