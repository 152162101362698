import React from "react";

const WhyChooseUs = () => {
  return (
    <section id="whyChooseUs" className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src="assets/img/star-icon.png" alt="image" /> People Love
                Us
              </span>
              <h2>
                Why Choose Us?<span className="overlay"></span>
              </h2>
              <p>
                We are a quality & established providing best solutions in IT
                industry and We provide the affordable solutions, expertise,
                friendly knowledgeable support, top-of-the-line software
                technologies, and web technologies to ensure you a successful
                online presence.
              </p>
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Quality is assured</h3>
                  <p>
                    We provide the latest infrastructure/hardware and our
                    network is located within state of the art data centre
                    facilities more information on which can be found here.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Customers Satisfaction</h3>
                  <p>
                    We are highly confident in our promise to serve you better.
                    Our focus is to build long term relationships with our
                    customers, not simply a quick sale!
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Professional Team Support</h3>
                  <p>
                    We are proud to provide our clients with support to solve
                    your problems and to ensure the continuous networking of
                    your business.
                  </p>
                </div>
                <div className="button-container" style={{ marginTop: "20px" }}>
                  <a
                    href="/request-quote"
                    className="default-btn wow fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <i className="flaticon-web"></i> Request Quote<span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image" data-tilt>
              <img src="assets/img/new/tech.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
