import React from "react";

const Advertising = () => {
  return (
    <div>
      <section className="pt-100 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Full Service Advertising</h2>
              <p>
                Advertising on social media is one of the best ways to target
                your ideal buyer persona. Advances in social media allow us to
                target users by age, hobbies, job role, marital status and more,
                allowing you to offer the most personalized ads.
              </p>
              <p>
                your story in an impactful and memorable way utilizing words,
                imagery, timing, placement and movement. Exquisite Digital
                Solution(E.D Solution), a full service advertising agency, is in
                tune with your brand, ideas, strategy, message and product and
                can help you live in the minds of your target audience.
              </p>
            </div>
            <div className="col-md-6">
              <div style={{ width: "100%" }}>
                <img src="assets/img/what-we-do/advertiser.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div style={{ width: "100%" }}>
                <img src="assets/img/what-we-do/advertising-2.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 pt-5">
              <h2>Just a few custom advertising specialties we offer:</h2>
              <ul>
                <li>Creative Development</li>
                <li>Copywriting</li>
                <li>Print: Magazine, Newspaper</li>
                <li>Direct Mail/Email Campaigns</li>
                <li>Outdoor: Billboards, Transit</li>
                <li>Multimedia Displays</li>
                <li>Online Ad Campaigns</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Advertising;
