export const SOLUTIONS = [
  {
    id: 1,
    icon: "flaticon-rocket",
    title: "Web Design / Interactive",
    description:
      "The Internet is an ever changing environment that demands that you keep up with the latest and greatest communication platforms. Your website is the first impression for your company and where customers make the decision to do business with you, decide what products to buy, learn about events that are scheduled, who to consult, and what information is most important to them. The Creative Momentum provides websites that by far, stand out from the rest of the competitors...",
    slug: "web-design-interactive",
    service_icon: 'assets/img/services/icon7.png'
  },
  {
    id: 2,
    icon: "flaticon-laptop",
    title: "MOBILE APP DEVELOPMENT",
    description:
      "With tablets and smartphones on the rise, nearly half of all internet users access online content from their mobile devices. If your website is not optimized to be viewed on mobile devices, then you may be losing customers. We design web sites that can be viewed on desktops as well as mobile devices.",
    slug: "mobile-app-development",
    service_icon: 'assets/img/services/icon8.png',
  },
  {
    id: 3,
    icon: "flaticon-user",
    title: "UI/UX",
    description:
      "We are passionate about our user experience because the user is ultimately the one who is the target. We focus on the wants and needs of the user to create stunning solutions through our experience in Research, Strategy, and Design methodologies...",
    slug: "ui-ux",
    service_icon: 'assets/img/services/icon9.png',
  },
  {
    id: 4,
    icon: "flaticon-segmentation",
    title: "SEO",
    description:
      "Search Engine Optimization(SEO) and branding are also services we offer. we help companies and startup rank up on search engines like google. We have a client-centric, strategic approach to Search. Each client has a unique set of circumstances when it comes to Search Marketing and that’s why a customized mix of PPC, SEO, Contextual Advertising is critical to climbing the ladder.",
    slug: "seo",
    service_icon: 'assets/img/services/icon10.png',
  },
  {
    id: 5,
    icon: "flaticon-web",
    title: "Branding / Corporate Identity",
    description:
      "Quality. Strength. Ability. Boldness. Trust. What does your brand say about you? When the time comes for a customer to decide on a brand will you be their first choice? Your brand needs to tell your story to the customer both consciously and subconsciously conveying the qualities that make you the best choice. The Creative Momentum is a group of brand specialists and we know that your brand is more than just a logo.",
    slug: "branding-corporate-identity",
    service_icon: 'assets/img/services/icon11.png',
  },
  {
    id: 6,
    icon: "flaticon-web",
    title: "Graphic Design",
    description:
      "Are you memorable? The differentiator between you and the competitor is your design and you stand out in an over populated marketplace. Our expert team of designers will craft the difference that gives you the validity and momentum your company needs to standout in the marketplace.",
    slug: "graphic-design",
    service_icon: 'assets/img/services/icon11.png',
  },
  {
    id: 7,
    icon: "flaticon-web",
    title: "Digital Marketing",
    description:
      "Are you planning to launch a website, campaign, product rollout, re-brand, conference, tradeshow, or a business event? Here at The Creative Momentum our team has a strong foundation in short-term and long-term planning with a keen business and marketing sense. The Creative Momentum can help you create that next sound plan to establish or keep market and business momentum.",
    slug: "digital-marketing",
    service_icon: 'assets/img/services/icon11.png',
  },  
  {
    id: 9,
    icon: "flaticon-settings",
    title: "Advertising",
    description: "Advertising is selectively telling your story in an impactful and memorable way utilizing words, imagery, timing, placement and movement. The Creative Momentum is in tune with your brand, ideas, strategy, message and product and can help you live in the minds of your target audience.",
    slug: 'advertising',
    service_icon: 'assets/img/services/icon11.png',
  },
  // {
  //   id: 8,
  //   icon: "flaticon-settings",
  //   title: "IT CONSULTANCY",
  //   description:
  //     "Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.",
  //   slug: "it-consultancy",
  //   service_icon: 'assets/img/services/icon12.png',
  // },
];
export const SOLUTION_MAP = {
  'web-design-interactive': {
    id: 1,
    icon: "flaticon-rocket",
    title: "Web Design / Interactive",
    description:
      "The Internet is an ever changing environment that demands that you keep up with the latest and greatest communication platforms. Your website is the first impression for your company and where customers make the decision to do business with you, decide what products to buy, learn about events that are scheduled, who to consult, and what information is most important to them. The Creative Momentum provides websites that by far, stand out from the rest of the competitors...",
    slug: "web-design-interactive",
    image: 'web-dev.png' 
  },
  "mobile-app-development":{
    id: 2,
    icon: "flaticon-laptop",
    title: "MOBILE APP DEVELOPMENT",
    description:
      "We develop an impactful mobile application for solutions. With great and appealing end-user experience as a top priority.",
    slug: "mobile-app-development",
    image: 'mobile-app-dev.png' 
  },
  "ui-ux":{
    id: 3,
    icon: "flaticon-user",
    title: "UI/UX",
    description:
      "We are passionate about our user experience because the user is ultimately the one who is the target. We focus on the wants and needs of the user to create stunning solutions through our experience in Research, Strategy, and Design methodologies...",
    slug: "ui-ux",
    image: 'ui-ux-1.png' 
  },
  "seo":{
    id: 4,
    icon: "flaticon-segmentation",
    title: "SEO",
    description:
      "Search Engine Optimization(SEO) and branding are also services we offer. we help companies and startup rank up on search engines like google.",
    slug: "seo",
    image: 'seo.png' 
  },
  "branding-corporate-identity": {
    id: 5,
    icon: "flaticon-web",
    title: "Branding / Company Identity",
    description:
      "Quality. Strength. Ability. Boldness. Trust. What does your brand say about you? When the time comes for a customer to decide on a brand will you be their first choice? Your brand needs to tell your story to the customer both consciously and subconsciously conveying the qualities that make you the best choice. The Creative Momentum is a group of brand specialists and we know that your brand is more than just a logo.",
    slug: "branding-company-identity",
    image: 'branding.png' 
  },
  "graphic-design": {
    id: 6,
    icon: "flaticon-web",
    title: "Graphic Design",
    description:
      "Are you memorable? The differentiator between you and the competitor is your design and you stand out in an over populated marketplace. Our expert team of designers will craft the difference that gives you the validity and momentum your company needs to standout in the marketplace.",
    slug: "graphic-design",
    image: 'graphic-design.png' 
  },
  "digital-marketing":{
    id: 7,
    icon: "flaticon-web",
    title: 'Digital Marketing',
    description: 'Are you planning to launch a website, campaign, product rollout, re-brand, conference, tradeshow, or a business event? Here at The Creative Momentum our team has a strong foundation in short-term and long-term planning with a keen business and marketing sense. The Creative Momentum can help you create that next sound plan to establish or keep market and business momentum.',
    slug: 'digital-marketing',
    image: 'digital-marketing.png' 
  },
  
  "advertising":{
    id: 9,
    icon: "flaticon-settings",
    title: "Advertising",
    description: "Advertising is selectively telling your story in an impactful and memorable way utilizing words, imagery, timing, placement and movement. The Creative Momentum is in tune with your brand, ideas, strategy, message and product and can help you live in the minds of your target audience.",
    slug: 'advertising',
    image: 'advertise-1.png' 
  },
  // "it-consultancy": {
  //   id: 8,
  //   icon: "flaticon-settings",
  //   title: "IT CONSULTANCY",
  //   description:
  //     "Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.",
  //   slug: "it-consultancy",
  //   image: 'branding.png' 
  // },
};
