import React from "react";
import { SOLUTIONS } from "../data/solution.data";
import { Link } from "react-router-dom";
import WeLikeToStartProject from "../component/WeLikeToStartProject";

const WhatWeDo = () => {
  const solutions = SOLUTIONS;
  return (
    <div>
      <section class="page-title-area page-title-style-two">
        <div class="container">
          <div class="page-title-content">
            <h2>What We Do</h2>
            <p>
              Exquisite Digital Solution (E.D Solution) is a full-service
              creative and digital marketing agency, specializing in custom web
              design and development for businesses that feel their branding
              strategy is "coasting" at best. It also  provides custom web design / interactive, digital marketing, SEO, Custom Graphic
              / Logo Design, Branding, Advertising, Media, Mobile Design and
              Strategic Planning to clients in high-tech B2B, consulting,
              security and non-profit.
            </p>
            <ul>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>What We Do</li>
            </ul>
          </div>
        </div>

        <div class="shape-img1">
          <img src="assets/img/shape/shape1.svg" alt="image" />
        </div>
        <div class="shape-img2">
          <img src="assets/img/shape/shape2.png" alt="image" />
        </div>
        <div class="shape-img3">
          <img src="assets/img/shape/shape3.png" alt="image" />
        </div>
      </section>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- Start Services Area --> */}
      <section class="services-area ptb-100">
        <div class="container">
          <div class="row">
            {solutions.map((solution) => (
              <div key={solution.id} class="col-lg-4 col-md-6 col-sm-12">
                <div
                  class="single-services-box-item wow fadeInUp"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <div class="icon">
                    <img src={solution.service_icon} alt="image" />
                  </div>
                  <h3>
                    <a href="single-services-1.html">{solution.title}</a>
                  </h3>
                  <p>{solution.description.substring(0, 200)}...</p>
                  <Link
                    to={`/what-we-do/${solution.slug}`}
                    className="view-details-btn"
                  >
                    Learn More
                  </Link>
                  <div class="shape">
                    <img src="assets/img/services/shape4.png" alt="image" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- End Services Area --> */}

      {/* <!-- Start Project Start Area --> */}
      <WeLikeToStartProject />
      {/* <!-- End Project Start Area --> */}

      {/* <!-- Start Industries Serve Area --> */}
      <section class="industries-serve-area bg-fafafb pt-100 pb-70">
        <div class="container">
          <div class="section-title">
            <h2>
              Industries We Serve<span class="overlay"></span>
            </h2>
            <p>
              We develop solution for various sectors inorder to digitalize it
              technical know-how and we solve problems related to:
            </p>
          </div>

          <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-factory"></i>
                </div>
                Manufacturing
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-hospital"></i>
                </div>
                Healthcare
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-tracking"></i>
                </div>
                Automobile
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-investment"></i>
                </div>
                Banking
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-house"></i>
                </div>
                Real Estate
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-order"></i>
                </div>
                Logistics
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-family-insurance"></i>
                </div>
                Insurance
                <a href="#" class="link-btn"></a>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div
                class="single-industries-serve-box wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div class="icon">
                  <i class="flaticon-bitcoin"></i>
                </div>
                Capital Markets
                <a href="#" class="link-btn"></a>
              </div>
            </div>
          </div>
        </div>

        <div class="vector-shape11">
          <img src="assets/img/shape/vector-shape11.png" alt="image" />
        </div>
      </section>
      {/* <!-- End Industries Serve Area --> */}
    </div>
  );
};

export default WhatWeDo;
