import React from 'react'

const GraphicDesign = () => {
    return (
        <div>
      <section className='pt-100 pb-100'> 
        <div className="container">
          <div className="row">
            <div className="col-md-7 pt-100 pb-100">
              <p>
              Are you memorable? The differentiator between you and the competitor is your design and you stand out in an over populated marketplace. Our expert team of designers will craft the difference that gives you the validity and momentum your company needs to standout in the marketplace.
              </p>
            </div>
            <div className="col-md-5">
              <img src="assets/img/what-we-do/" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className='pt-100 pb-100'>
        <div className="container ">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 pt-100 pb-100">
              <h4>
              Just a few custom graphic design specialties we offer:
              </h4>
              <ul>
                <li>Logo Design</li>
                <li>Brochure Design</li>
                <li>Package Design</li>
                <li>Graphic Standards Manuals</li>
                <li>Collateral Development</li>
                <li>Annual Reports</li>
                <li>Point-of-Purchase Displays</li>
                <li>Multimedia Presentations</li>
                <li>Trade Show Display Design</li>
                <li>Print Production</li>
                <li>Print Requisition Systems</li>
                <li>Way Finding Systems</li>
                <li>Product Marketing</li>
                <li>Point of Sale</li>
                <li>Packaging</li>
                <li>Copywriting</li>
                <li>Sales &amp; General Presentations</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}

export default GraphicDesign
