import React from "react";
import { Link } from "react-router-dom";
import { SOLUTIONS } from "../data/solution.data";

const Solution = () => {
  const solutions = SOLUTIONS;

  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <h2>
          Our Services
        </h2>
        <div className="row">
          {solutions.map((solution) => (
            <div key={solution.id} className="col-lg-4 col-md-6 col-sm-6">
              <div
                className="single-solutions-box wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="icon">
                  <i className={solution.icon}></i>
                </div>
                <h3>
                  <a href={`/what-we-do/${solution.slug}`}>{solution.title}</a>
                </h3>
                <p>{solution.description.substring(0, 200)}...</p>
                <a
                  href={`/what-we-do/${solution.slug}`}
                  className="view-details-btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solution;
