import React from "react";
import { PROJECTS } from "../data/recent-project.data";

const RecentProject = () => {
  const projects = PROJECTS;

  return (
    <section className="projects-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src="assets/img/star-icon.png" alt="image" /> Recent Projects
          </span>
          <h2>
            Check Some Of Our Recent Work<span className="overlay-1"></span>
          </h2>
        </div>

        <div className="row">
          {projects.map((project) => (
            <div key={project.id} className="col-lg-4 col-md-6">
              <div
                className="single-projects-box wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="image">
                  <img src={project.imageUrl} alt="image" />

                  <a target="_blank" href={project.url} className="link-btn">
                    <i className="bx bx-plus"></i>
                  </a>
                </div>

                <div className="content">
                  <h3>
                    <a target="_blank" href={project.url}>
                      {project.title}
                    </a>
                  </h3>
                  <span>{project.solution}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RecentProject;
