import React from "react";
import { Link } from "react-router-dom";

const WebDesign = () => {
  return (
    <div>
      {/* <section>
        <div className="container">
          <div className="section-title">
            <h2>Web Design</h2>
            <p>
              What does your website do for you? Does it engage visitors? Does
              it tell your story and demonstrate your brand’s value? Does it add
              value? Whether you need to improve sales or reputation, revitalize
              an aging website or build a new one from scratch, The Creative
              Momentum offers comprehensive web design and development services
              to help you better connect with your customers.
            </p>
            <Link to={`/request-quote`} className="default-btn">
              Request Quote Today
            </Link>
          </div>
        </div>
      </section> */}

      <section className="sitemap pt-100 pb-50 bg-fafafb">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-100">
              <h2>Sitemap UI/UX</h2>
              <p>
                We start with hierarchical diagrams of your website to ensure
                that all website pages coordinate in a way that supports a
                clear, intuitive user experience.
              </p>
            </div>
            <div className="col-md-6">
              <div style={{ height: "400px", width: "100%" }}>
                <img src="assets/img/what-we-do/sitemap-optimized.jpg" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wireframe pt-100 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div style={{ height: "400px", width: "100%" }}>
                <img src="assets/img/what-we-do/wireframe.jpg" />{" "}
              </div>
            </div>
            <div className="col-md-6 pt-100">
              <h2>Wireframe Design</h2>
              <p>
              Structured website wireframe design that incorporates content, functionality, user needs, and other key interface elements your site needs to make an impact on your audience.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sitemap pt-100 pb-50 bg-fafafb">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-100">
              <h2>UI/UX Design</h2>
              <p>
              Our approach to user interface/user experience web design is based on methodical research, strategic planning, and collaboration. We’ll review your website assets and come up with an experiential design plan that keeps your end user at the heart of all decisions.
              </p>
            </div>
            <div className="col-md-6">
              <div style={{ height: "400px", width: "100%" }}>
                <img src="assets/img/what-we-do/uiux_image.jpg" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rwd pt-100 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div style={{ height: "400px", width: "100%" }}>
                <img src="assets/img/what-we-do/rwd-2.jpg" />{" "}
              </div>
            </div>
            <div className="col-md-6 pt-100">
              <h2>Responsive Web Design</h2>
              <p>
              If your website is not optimized to be viewed on mobile devices, then you may be losing customers. We design web sites that can be viewed on desktops as well as mobile devices, or we can take it a step further, and design a dedicated mobile site that your customers can be automatically directed to while on-the-go. To make the website compatible and adapt itself to devices with different resolutions and sizes, the responsive design will play an integral part in developing any web application or site.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sitemap pt-100 pb-50 bg-fafafb">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-100">
              <h2>HealthCare Website</h2>
              <p>
              Moving your healthcare services online. We’ll help you achieve your client outreach dreams with your platform to grow your online presence.
              </p>
            </div>
            <div className="col-md-6">
              <div style={{ height: "400px", width: "100%" }}>
                <img src="assets/img/what-we-do/healsoul.png" />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="rwd pt-100 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div style={{ height: "400px", width: "100%" }}>
                <img src="assets/img/what-we-do/ecommerce.jpg" />{" "}
              </div>
            </div>
            <div className="col-md-6 pt-100">
              <h2>E-Commerce Website</h2>
              <p>
              Start selling online with a dedicated ecommerce website. We’ll help coordinate your commerce goals with your platform and design a storefront that drives ongoing revenue for your business.
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default WebDesign;
