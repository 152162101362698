import React from "react";

const DigitalMarketing = () => {
  return (
    <div>
      <section className="pt-100 pb-100 bg-fafafb">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <p>
                A gorgeous website is of no use if no one sees it. Marketing to
                a targeted audience and nurturing those visitors through the
                buyers' journey is the easiest, fastest way to get leads you can
                convert into customers.{" "}
              </p>
              <p>
                Working with The Exquisite Digital Solution gives you access to
                leaders in the modern digital marketing space – with proven
                results. Our team is even capable of a managing a new product
                rollout, a brand refresh and coordinating tradeshows,
                conferences or other business events.
              </p>
              <p>
                We keep a strong team in-house because the best service is
                full-service. Our Marketing Managers invest their time and are
                passionate about the products, people and services they
                represent. Businesses invest in us because we invest in their
                success.{" "}
              </p>
            </div>
            <div className="col-md-4">
              <div style={{ width: "100%" }}>
                <img
                  src="assets/img/what-we-do/digital-marketing-1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="container-fluid"
        style={{
          width: "100%",
          height: "200px",
          backgroundImage: `url('assets/img/what-we-do/digital-market-landing.jpg')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></section>

      <section className="pt-100 pb-100 bg-fafafb">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Our Marketing Menu</h2>
              <ul>
                <li>Social Media Marketing</li>
                <li>Email Marketing</li>
                <li>Product Marketing</li>
                <li>Content Audit and Strategy</li>
                <li>SEO &amp; Navigation Best Practices</li>
                <li>PR &amp; Communications</li>
                <li>Brand Strategy</li>
                <li>Launch Strategy</li>
                <li>Sales &amp; General Presentations</li>
                <li>Migration Strategy</li>
                <li>Conference Planning</li>
                <li>Business Events</li>
                <li>Product Rollout</li>
                <li>Campaign Planning</li>
              </ul>
            </div>
            <div className="col-md-6">
              <h2>Marketing Software Management</h2>
              <ul>
                <li>
                  Total management of any marketing software program, including
                  HubSpot, Pardot, Marketo, and more
                </li>
                <li>We will eliminate Junk leads</li>
                <li>
                  Automate the consolidation of duplicates within your CRM
                </li>
                <li>Objective Lead Qualification</li>
                <li>Automated Lead Management</li>
                <li>Dynamic List Segmentation</li>
                <li>Closed Loop Campaign ROI Reportings</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 pb-100">
        <div className="container">
          <h4>
            Campaign Management We Give You Increased Visibility Have a 360
            Degree View of Marketing Interactions Daily &amp; Real Time Sales
            Alerts
          </h4>
          <div className="row">
            <div className="col-md-12">
              <h5>We Implement Advanced Marketing Functionality</h5>
              <p>
                Prospect nurturing / drip marketing based on Solution Selling
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h5>Objective Lead Qualification</h5>
              <p>
                Lead qualification based upon objective criteria like explicit
                and implicit criteria
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5>Capturing Prospect Intent</h5>
              <p>Site search captures intent of prospect</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5>Keeping the Overall Cost Per Prospect (CPO) Low</h5>
              <p>
                Personalized “drip” email campaigns keep your organization top
                of mind with Prospects and tailor the message based upon
                prospect behavior
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h5>Anonymous Visitors are great for Lead Generation</h5>
              <p>We capture anonymous visitors, which can generate you leads</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DigitalMarketing;
