import React from "react";

const BrandingCorporateIdentity = () => {
  return (
    <div>
      <section className='pt-100 pb-100'> 
        <div className="container">
          <div className="row">
            <div className="col-md-7 pt-100 pb-100">
              <p>
                When the time comes for a customer to decide on a brand will you
                be their first choice? Your brand needs to tell your story to
                the customer both consciously and subconsciously conveying the
                qualities that make you the best choice. The Creative Momentum
                is a group of brand specialists and we know that your brand is
                more than just a logo.
              </p>
            </div>
            <div className="col-md-5">
              <img src="assets/img/what-we-do/" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className='pt-100 pb-100'>
        <div className="container ">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 pt-100 pb-100">
              <h4>
                Just a few of our custom branding and corporate identity design
                specialties we offer:
              </h4>
              <ul>
                <li>Brand Evaluation</li>
                <li>Name Development</li>
                <li>Logo Design / Corporate Identity</li>
                <li>Brand Guidelines</li>
                <li>Taglines</li>
                <li>Company &amp; Product Naming</li>
                <li>Content Strategy</li>
                <li>Copywriting</li>
                <li>Product Marketing</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrandingCorporateIdentity;
