import React from 'react'

const Faq = () => {
    return (
        <div>

        <section className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>Frequently Asked Questions</h2>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li>FAQ's</li>
                    </ul>
                </div>
            </div>

            <div className="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"/></div>
            <div className="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"/></div>
            <div className="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"/></div>
        </section>
       
        <section className="faq-area ptb-100">
            <div className="container">
                <div className="tab faq-accordion-tab">
                    <ul className="tabs d-flex flex-wrap justify-content-center">
                        <li><a href="#"><i className='bx bx-flag'></i> <span>Getting Started</span></a></li>
                        
                        <li><a href="#"><i className='bx bxs-badge-dollar'></i> <span>Pricing & Planes</span></a></li>

                        <li><a href="#"><i className='bx bx-shopping-bag'></i> <span>Sales Question</span></a></li>

                        <li><a href="#"><i className='bx bx-book-open'></i> <span>Usage Guides</span></a></li>

                        <li><a href="#"><i className='bx bx-info-circle'></i> <span>General Guide</span></a></li>
                    </ul>

                    <div className="tab-content">
                        <div className="tabs-item">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <li className="accordion-item">
                                        <a className="accordion-title active" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q1. What is Data Science? List the differences between supervised and unsupervised learning.
                                        </a>
        
                                        <div className="accordion-content show">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q2. What is Selection Tracer.com?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q3. What is tracer-variance trade-off?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q4. What is a confusion matrix?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q5. What is the difference between “long” and “wide” format data?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tabs-item">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q1. What is Data Science? List the differences between supervised and unsupervised learning.
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q2. What is Selection Tracer.com?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q3. What is tracer-variance trade-off?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q4. What is a confusion matrix?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q5. What is the difference between “long” and “wide” format data?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tabs-item">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q1. What is Data Science? List the differences between supervised and unsupervised learning.
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q2. What is Selection Tracer.com?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q3. What is tracer-variance trade-off?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q4. What is a confusion matrix?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q5. What is the difference between “long” and “wide” format data?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tabs-item">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q1. What is Data Science? List the differences between supervised and unsupervised learning.
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q2. What is Selection Tracer.com?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q3. What is tracer-variance trade-off?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q4. What is a confusion matrix?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q5. What is the difference between “long” and “wide” format data?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tabs-item">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q1. What is Data Science? List the differences between supervised and unsupervised learning.
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q2. What is Selection Tracer.com?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q3. What is tracer-variance trade-off?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q4. What is a confusion matrix?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>

                                    <li className="accordion-item">
                                        <a className="accordion-title" href="javascript:void(0)">
                                            <i className='bx bx-chevron-down'></i>
                                            Q5. What is the difference between “long” and “wide” format data?
                                        </a>
        
                                        <div className="accordion-content">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="project-start-area bg-color ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <img src="assets/img/project-start1.png" data-speed="0.06" data-revert="true" alt="image" style={{transform: 'matrix(1, 0, 0, 1, 30.34, -15.53)'}} />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2 className="wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '0ms', animationName: 'fadeInUp',}}>We Like to Start Your Project With Us</h2>
                            <p className="wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '100ms', animationName: 'fadeInUp'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <a href="#" className="default-btn wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms" style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '200ms', animationNname: 'fadeInUp'}}><i className="flaticon-user"></i>Get Started<span style={{top: '-83.183px', left: '126.5px',}}></span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape9"><img src="assets/img/shape/vector-shape9.png" alt="image"/></div>
            <div className="vector-shape10"><img src="assets/img/shape/vector-shape10.png" alt="image"/></div>
        </section>
        
        </div>
    )
}

export default Faq
