import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import './App.css';

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import DefaultLayout from "./component/DefaultLayout";
import Portfolio from "./pages/Portfolio";
import WhatWeDo from "./pages/WhatWeDo";
import Solution from "./component/Solution";
import SolutionPage from "./pages/SolutionPage";
import Quote from "./pages/Quote";
import Page404 from "./pages/Page404";

import $ from 'jquery';

const App = () => {
  return (
    <Router>
      <DefaultLayout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/what-we-do" component={WhatWeDo} />
          <Route path="/what-we-do/:solution" component={SolutionPage} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/solution" component={Solution} />
          {/* <Route path="/solution/:solution" component={SolutionPage} /> */}
          <Route exact path="/request-quote" component={Quote} />
          <Route path="/request-quote/:requestQuote" component={Quote} />

          <Route component={Page404} />
        </Switch>
      </DefaultLayout>
    </Router>
  );
};

export default App;
