import React from "react";
import { Router, Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="navbar-area">
      <div className="tracer-responsive-nav">
        <div className="container">
          <div className="tracer-responsive-menu">
            <div className="logo">
              <a href="/">
                <img
                  src="assets/img/logo.png"
                  alt="logo"
                  style={{ width: "240px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="tracer-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand">
                <img
                  src="assets/img/logo.png"
                  alt="logo"
                  style={{ width: "240px" }}
                />
              </Link>

            </div>

            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" activeclassname="active" className="nav-link">
                    Home{" "}
                  </Link>
                </li>

                <li className="nav-item">
                  <a href="/about-us"
                    activeclassname="active"
                    className="nav-link"
                  >
                    About Us{" "}
                  </a>
                </li>

                <li className="nav-item">
                  <a href="/what-we-do"
                    activeclassname="active"
                    className="nav-link"
                  >
                    What We do
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                    <a href="/what-we-do"
                    activeclassname="active"
                    className="nav-link"
                  >
                        Overview
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="/what-we-do/web-design-interactive" className="nav-link">
                        Web Design / Interactive
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/mobile-app-development' className="nav-link">
                        Mobile App Development
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/ui-ux' className="nav-link">
                        UI/UX
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/branding-corporate-identity' className="nav-link">
                        Branding/Corporate Identity
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/graphic-design' className="nav-link">
                        Graphic Design
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/digital-marketing' className="nav-link">
                        Digital Marketing
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/seo' className="nav-link">
                        SEO 
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href='/what-we-do/advertising' className="nav-link">
                        Advertising
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a href="/contact-us"
                    activeClassName="active"
                    className="nav-link"
                  >
                    Contact
                  </a>
                </li>
              </ul>

              <div className="others-option d-flex align-items-center">
                <div className="option-item">
                  <a
                    href="https://blog.edsolution.co.uk"
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i>Blog<span></span>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="others-option-for-responsive">
        <div className="container">
          <div className="dot-menu" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            <div className="inner">
              <div className="circle circle-one"></div>
              <div className="circle circle-two"></div>
              <div className="circle circle-three"></div>
            </div>
          </div>

          <div className="container">
            <div className="option-inner">
              <div className="others-option">
                <div className="option-item">
                  <a href="/contact-us" className="default-btn">
                    <i className="flaticon-right"></i>Get Started<span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
