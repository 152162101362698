import React from "react";

const MobileAppDevelopment = () => {
  return (
    <div>
      <section className="showcase pt-100 pb-50 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="assets/img/what-we-do/mobile-design.png"
                alt="mobile app design"
              />
            </div>
            <div className="col-md-6 pt-100">
              <h2>Mobile Design</h2>
              <p>
                Our developers specialize in branded mobile app design that
                features quick loading times, simple interfaces, and clear
                navigation that supports a great experience for every user.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="app-dev pt-100 pb-50 bg-fafafb">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-100">
              <h2>Cross-Platform Development</h2>
              <p>
                Over the years, cross-platform mobile development has earned a
                reputation for being one of the most popular software
                development trends. We adopt the cross-platform approach allowing our engineers to build
                apps for different platforms with a single codebase, thus saving
                time, money, unnecessary effort and ensures satisfying the market demand by smartphone users.
              </p>
            </div>

            <div className="col-md-6">
              <img
                src="assets/img/what-we-do/mobile-app-design-1.jpg"
                alt="mobile app design"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MobileAppDevelopment;
