import React from 'react'
import AboutUsBanner from '../component/AboutUsBanner'
import History from '../component/History'
import Team from '../component/Team'
import Partner from '../component/Partner'
import AboutUsHeader from '../component/AboutUsHeader'

const About = () => {
    return (
        <div>
            <AboutUsHeader />
            <AboutUsBanner />
            {/* <History /> */}
            <Team />
            {/* <Partner/> */}
        </div>
    )
}

export default About
