import React from "react";

const Seo = () => {
  return (
    <div>
      <section className="pt-100 pb-100">
        <div className="container">
          <h2>
            Not Just Another Atlanta SEO Agency! <br />
            Strategic Search Engine Optimization Campaigns Customized to Your
            Goals
          </h2>
          <p style={{ fontStyle: "italic" }}>Need SEO that Creates Momentum?</p>
          <p>Adaptive Marketing is our philosophy...</p>
          <p>
            It’s easy to get on the first page in the top 10 rankings on search
            engines, when you follow our lead. We are accountable and set
            realistic expectations based on your goals and budget, which we can
            achieve, improve, redefine, and repeat. It’s not just about being
            number one! It’s all about what happens when searchers see your site
            on page one, and everything thereafter.
          </p>
          <p>
            We make sure your foundation is solid for both your users and search
            engines. Why optimize for search engines, when you can optimize for
            your customer?! After all, search engines are creating algorithms
            that are meant to interpret our needs and the best solution.
            Google’s underlying principle is that they want to take all the work
            out of research and provide the right solution every time a search
            is initiated.
          </p>
          <p>
            Yes, we optimize technically to be ranked number one, while we love
            to ultimately optimize to target your user personas based on where
            they are in the buyer’s journey! When you position the right content
            that comes up in a search for the intended keyword, you are helping
            your customers and ultimately growing your revenue. The rest of the
            momentum we create comes in the experience after they land on your
            site. SEO is not just about being #1, it’s about creating the best
            content that directly relates to your user’s need. It’s this great
            content that not only helps organic rankings, but can also be
            leveraged by all other Inbound media channels.
          </p>
          <p>
            Our approach to SEO is not just about rankings and cookie cutter
            reports with recommendations. We provide SEO strategies based on
            campaigns; we are all about driving qualified leads that continually
            improve your ROI while expanding your organic positioning with great
            content creation!
          </p>
          <p>
            <img
              src="assets/img/what-we-do/Adaptive-Marketing-Approach.png"
              alt=""
            />
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <h2>We do everything every other SEO agency does, and much more!</h2>
          <ul>
            <li>Sitewide Technical Analysis and Repair of all SEO Factors</li>
            <li>
              Holistic sitewide optimization (we‘ve never had to recover from
              penalties or algorithm updates)
            </li>
            <li>SEO that leverages Inbound methodologies</li>
            <li>Optimization of Organic Conversion Funnels</li>
            <li>Daily health and rankings monitoring</li>
            <li>Local Optimization</li>
            <li>Social Optimization</li>
            <li>Structured Data Integration</li>
            <li>Content Creation</li>
            <li>Content Strategy &amp; Calendar</li>
            <li>Link Audits (and disavowal when needed)</li>
            <li>Mobile Analysis and Optimization</li>
            <li>Internal &amp; External Duplicate Content Analysis</li>
            <li>Ecommerce Best Practices</li>
            <li>Daily Rank Checking</li>
            <li>
              Monthly reporting around content issues and wins, traffic,
              rankings, conversions, and ROI
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div className="container">
          <h2>The Things that take SEO to the Next Level</h2>
          <ul>
            <li>
              Persona &amp; Buyer’s Journey/Sales Cycle based optimization
            </li>
            <li>Process &amp; Training for Internal Client Teams</li>
            <li>SEO Tools Analysis &amp; Recommendations</li>
            <li>
              Rich Media &amp; Content Upgrades for Engagement and Conversion
              Tracking
            </li>
            <li>
              Analytics Analyses and Data Quality Monitoring &amp; Assurances
            </li>
            <li>Web Performance Monitoring</li>
            <li>International Optimization</li>
            <li>Content Management &amp; Curation</li>
            <li>
              Campaign Continuity across Paid, Owned, and Earned Media Channels
            </li>
            <li>Advanced Analytics Strategies &amp; Integrations</li>
            <li>
              For website traffic analysis and conversion/engagement analysis
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div className="container">
          <h2>Things we analyze to do what we do!</h2>
          <ul>
            <li>Historical Organic Analysis and Insights</li>
            <li>Current Organic Market Share Analysis</li>
            <li>Competitor Deep Dives</li>
            <li>Technical Audits of Onpage and Offpage factors</li>
            <li>Content &amp; Gaps Analyses</li>
            <li>Organic Conversion Analysis</li>
            <li>Usability (UX) Testing Analysis for Organic Traffic</li>
            <li>Information Architecture (IA) Analysis as it relates to SEO</li>
            <li>Local Profile Analysis</li>
            <li>Social Analysis</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Seo;
