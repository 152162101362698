import React from "react";
import { Link } from "react-router-dom";

const WeLikeToStartProject = () => {
  return (
    <section className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img
                src="assets/img/new/dev.jpg"
                data-speed="0.06"
                data-revert="true"
                alt="image"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2
                className="wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                We Like to Start Your Project With Us
              </h2>
              <a
                href="tel:+447884959911"
                className="default-btn wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <i className="flaticon-web"></i>Call us now<span></span>
              </a>{"   "}
              
              <Link
                to="/contact-us"
                className="default-btn wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms" 
              >
                <i className="flaticon-web"></i>Contact us<span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src="assets/img/shape/circle-shape1.png" alt="image" />
      </div>
    </section>
  );
};

export default WeLikeToStartProject;
