import React from "react";

const AboutUsBanner = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div
              className="about-image wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
              <img src="assets/img/new/idea-bulb.jpg" alt="image" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src="assets/img/star-icon.png" alt="image" /> About Us
                </span>
                <h2>Exquisite Digital Solution</h2>
                <p>
                  This is a full-service creative and digital marketing agency,
                  specializing in custom web design and development for
                  businesses that feel their branding strategy is
                  &quot;coasting&quot; at best. Exquisite digital solution
                  provides custom Web/Interactive, Marketing, SEO, Custom
                  Graphic/Logo Design, Branding, Advertising, Media, Mobile
                  Design and Strategic Planning to clients in high-tech B2B,
                  consulting, security and non-profit.
                </p>
                {/* <ul className="features-list">
                  <li>
                    <span>
                      <div className="icon">
                        <img src="assets/img/icon1.png" alt="image" />
                      </div>
                      <h3>3 Years</h3>
                      <p>On the market</p>
                    </span>
                  </li>

                  <li>
                    <span>
                      <div className="icon">
                        <img src="assets/img/icon1.png" alt="image" />
                      </div>
                      <h3>15+</h3>
                      <p>Team members</p>
                    </span>
                  </li>

                  <li>
                    <span>
                      <div className="icon">
                        <img src="assets/img/icon1.png" alt="image" />
                      </div>
                      <h3>100%</h3>
                      <p>Satisfaction rate</p>
                    </span>
                  </li>

                  <li>
                    <span>
                      <div className="icon">
                        <img src="assets/img/icon1.png" alt="image" />
                      </div>
                      <h3>80%</h3>
                      <p>Senior scientist</p>
                    </span>
                  </li>
                </ul> */}
                <p>
                  At Exquisite digital solution we are proud of our enabling
                  technology services that add value to our business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our History</h3>
                <p>
                  We have been in existing since 2017, developing solutions for
                  businesses around the world. With such great minds we over the
                  years has been working with top-notch technologies on various
                  platforms and sectors in the IT industry.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our Mission</h3>
                <p>
                  As a Milton Keynes-based full service creative agency, we give
                  your company the momentum it needs to bridge that gap and to
                  get the conversation started. Moving in the right direction to
                  align with your market is what we bring to the table. As a
                  team with the leading industry knowledge and our fixation with
                  top of the line
                </p>

                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> web design / interactive
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Mobile App Development{" "}
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> UI/UX
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Branding/Corporate
                    Identity
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Graphic Design
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Digital Marketing
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> SEO
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Advertising

                  </li>
                </ul>
              </div>
            </div>
            {/* 
            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="about-text">
                <h3>Who we are</h3>
                <p>
                  Real innovations and a positive customer experience are the
                  heart of successful communication.
                </p>

                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Stay real. Always.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> We have you covered
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> We turn heads
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Your brand, promoted
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src="assets/img/shape/circle-shape1.png" alt="image" />
      </div>
    </section>
  );
};

export default AboutUsBanner;
