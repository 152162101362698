import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <a href="index.html" className="logo">
                <img
                  src="assets/img/logo.png"
                  alt="logo"
                  style={{ width: "240px" }}
                />
              </a>
              <p>
                Exquisite digital solution is a full-service creative and
                digital agency, specializing in core software design and
                development for businesses that feel their branding strategy is
                &quot;coasting&quot; at best.
              </p>
              <ul className="social-link">
                <li>
                  <a href="#" className="d-block" target="_blank">
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-block" target="_blank">
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="d-block" target="_blank">
                    <i className="bx bxl-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/edsolution-exquisite-digital-solution-a2a2b21b3/" className="d-block" target="_blank">
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Explore</h3>
              <ul className="footer-links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About</Link>
                </li>
                <li>
                  <Link to="/what-we-do">What we do</Link>
                </li>
                {/* <li>
                  <Link to="/portfolios">Portfolios</Link>
                </li> */}
                <li>
                  <Link to="/contact-us">Contact</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Services</h3>
              <ul className="footer-links-list">
                <li className="nav-item">
                  <a href="/what-we-do/web-design-interactive" >
                    Web Design / Interactive
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/mobile-app-development' >
                    Mobile App Development
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/ui-ux' >
                    UI/UX
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/branding-corporate-identity' >
                    Branding/Corporate Identity
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/graphic-design' >
                    Graphic Design
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/digital-marketing' >
                    Digital Marketing
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/seo' >
                    SEO
                  </a>
                </li>

                <li className="nav-item">
                  <a href='/what-we-do/advertising' >
                    Advertising
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>
              <ul className="footer-contact-info">
                {/* <li>
                  <i className="bx bx-map"></i>4 Grace Garden, <br />
                  Eaton Leys
                  <br />
                  Milton keynes
                  <br />
                  MK17 9GD
                </li> */}
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+441753371691">+44 1753371691 </a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:info@edsolution.co.uk">
                    info@edsolution.co.uk
                  </a>
                </li>
                <li>
                  <i className="bx bxs-inbox"></i>
                  <a href="tel:+44 7884 959911">+44 7884 959911</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                <i className="bx bx-copyright"></i>copyright{" "}
                {new Date().getFullYear()} <strong>E.D Solution</strong> | All
                rights reserve
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <a href="privacy-policy.html">Privacy Policy</a>
                </li>
                <li>
                  <a href="terms-of-service.html">Terms & Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src="assets/img/footer-map.png" alt="image" />
      </div>
    </footer>
  );
};

export default Footer;
